<template>
  <div class="userInfo-page" :style="`height:${clientHeight}px`" @click="showFalse">
    <join-organization />
    <div class="head">
      <div class="left">
        <van-icon name="arrow-left" class="arrow" @click.stop="back" />
        <van-cell title="个人中心" />
      </div>
    </div>
    <div class="user-header">
      <img :src="avatarUrl" alt="" @click="addImg" />
      <div style="flex: 1">
        <van-field v-model="inputname" placeholder="请输入用户名" @blur="save()" />
      </div>
    </div>
    <van-field class="vans" v-model="inputidcard" clearable label="身份证号" placeholder="请输入身份证号" @blur="save()" />
    <van-field class="vans" v-model="city" clearable label="所在区县" placeholder="请输入所在区县" @input="inputCity" @focus="cityFocus" @blur="cityBlur" />
    <ul class="numlist" v-show="cityShow" @scroll="cityOnLoad($event)">
      <li v-for="(item, i) in cityList" :key="i" @click="cityClick(item)">
        {{ item.provinceCityRegion }}
      </li>
    </ul>
    <div class="member-box">
      <div style="flex: 1">
        <van-field class="vans" :value="phoneHide(inputphone)" clearable label="手机号" :disabled="true" />
      </div>
      <div @click="toMember">
        <i class="iconfont icon-huiyuan1"></i>
        <span v-if="userInfo.memberStatus === 1" style="color: #ff9900">剩余{{ userInfo.memberRemainingDays }}天</span>
        <span v-else>成为会员</span>
      </div>
    </div>
    <div class="line"></div>
    <van-field v-if="orgName" class="vans" v-model="orgName" clearable label="所属组织" :disabled="true" />
    <div class="box" @click="joinOrg" v-else>
      <div class="box-left">所属组织</div>
      <div class="box-right" style="color: #969799">未加入组织，点击申请加入</div>
    </div>
    <div class="box">
      <div class="box-left">联系方式</div>
      <div class="box-right">
        <i class="iconfont icon-wechat" @click.stop="onContact('wechat')"></i>
        <i class="iconfont icon-qq" @click.stop="onContact('qq')"></i>
        <i class="iconfont icon-mail" @click.stop="onContact('email')"></i>
      </div>
    </div>
    <div class="line"></div>
    <div class="contact" v-show="isShowContact" @click.stop="">
      <van-field class="vans" v-show="contact === 'wechat'" v-model="weixin" placeholder="请输入微信号" @blur="save()" />
      <van-field class="vans" v-show="contact === 'qq'" v-model="qq" placeholder="请输入QQ号" @blur="save()" />
      <van-field class="vans" v-show="contact === 'email'" v-model="email" placeholder="请输入邮箱" @blur="save()" />
    </div>
    <van-field class="vans" v-model="mechanismName" clearable label="常去机构" placeholder="请输入机构名称" @input="inputMechanismName" @focus="mechanismFocus" />
    <ul class="numlist" v-show="mechanismShow">
      <li>
        <div class="org-title">
          <div v-for="(item, index) in orgsTypeList" :key="index" @click.stop="orgsTypeClick(item)" :style="orgsType === item ? 'color:#0066FF' : ''">
            {{ item }}
          </div>
        </div>
      </li>
      <li v-for="(item, i) in mechanismList" :key="i" @click.stop="mechanismClick(item)">
        {{ item.name }}
      </li>
    </ul>
    <div class="orgs-list">
      <div class="orgs-name" v-for="(item, i) in orgs" :key="i">
        {{ item.name }}
        <i class="iconfont icon-clear" style="cursor: pointer" @click="deleteOrgsName(item, i)"></i>
      </div>
    </div>
  </div>
</template>
<script>
import goBackMixin from '@mixins/goBack'
import { qiniuBase } from '~api-config'
import { mapState } from 'vuex'
import JoinOrganization from '@/components/join-organization'

export default {
  name: 'user-info',
  mixins: [goBackMixin],
  components: {
    JoinOrganization,
  },
  data() {
    return {
      clientHeight: document.documentElement.clientHeight,
      avatarUrl: '',
      inputname: '',
      inputidcard: '',
      weixin: '',
      qq: '',
      email: '',
      inputphone: '',
      city: '',
      orgName: '',
      organizationId: 0,
      orgs: [],
      cityShow: false,
      cityList: [],
      cityPage: 0,
      isShowContact: false, // 是否显示联系方式
      contact: 'wechat', // 显示那个输入框
      mechanismList: [], // 常去机构
      mechanismShow: false,
      mechanismName: '',
      orgsTypeList: ['法院', '检察院', '派出所', '公安局'],
      orgsType: '法院',
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
  },
  methods: {
    // 返回上一个页面
    back() {
      if (this.$router.length <= 1) {
        console.log('直接返回到原生端')
        this.backToNative()
      } else {
        history.back(-1)
      }
    },
    // 点击头像
    addImg() {
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.toAndrPickFile(3)
        return false
      } else {
        if (document.getElementById('file')) {
          document.getElementById('file').remove()
        }
        const ipt = document.createElement('input')
        ipt.id = 'file' // 设置ID以便删除
        ipt.type = 'file'
        // ipt.multiple = true // 开启支持多选
        ipt.style.display = 'none'
        document.body.appendChild(ipt)
        document.getElementById('file').onchange = () => {
          const f = ipt.files[0]
          const qiniu = new this.$qiniu()
          qiniu.UploadFile(
            'DEFAULT',
            f,
            {
              next: (file) => {},
              complete: (res, file) => {},
              error: () => {
                this.$notify('上传失败，请重新选择')
              },
            },
            (fileopt, key, file) => {
              this.avatarUrl = qiniuBase + '/' + key
              this.save()
              document.getElementById('file').remove()
            }
          )
        }
        ipt.click()
      }
    },
    // 接收原生端上传回调
    uploadFilesResultCallBack(data) {
      console.log('接收原生端上传回调===>', data)
      const list = JSON.parse(data)
      this.callingCardUrl = list[0].url
      this.save()
    },
    // 获取城市列表
    getCityList() {
      this.$axios.get(`${this.$base}/document/basicAdministrativeRegion?param=${this.city}&pageNum=${this.cityPage}`).then((res) => {
        const { code, data } = res.data
        if (code === '200') {
          // 如果页码是0，那就替换原来的数组
          if (this.cityPage === 0) {
            this.cityList = data.items
          } else {
            // 如果页码不是0，那就在原来的数组的基础上加上数据
            this.cityList = this.cityList.concat(data.items)
          }
          // 如果返回的数据的长度小于每页的大小，那就说明没数据了，不允许继续下拉
          if (data.items.length < this.size) {
            this.isRequest = false
          } else {
            this.isRequest = true
          }
        }
      })
    },
    cityOnLoad(e) {
      console.log(e)
      if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight && this.isRequest) {
        this.cityPage++
        this.getCityList()
      }
    },
    // 城市列表点击事件
    cityClick(item) {
      this.city = item.provinceCityRegion
    },
    cityFocus() {
      this.cityShow = true
      this.getCityList()
    },
    cityBlur() {
      this.cityPage = 0
      setTimeout(() => {
        this.cityShow = false
        this.save()
      }, 200)
    },
    inputCity() {
      this.cityPage = 0
      this.getCityList()
    },
    // 手机号显示前后3位
    phoneHide(phone) {
      return phone.substring(0, 3) + '*****' + phone.substring(phone.length - 3, phone.length)
    },
    // 点击成为会员
    toMember() {
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.toVipRechargeView()
      } else if (ua === 'fb-flutter-Webview') {
        // eslint-disable-next-line
        console.log('ua...flutter')
        window.toVipRechargeView.postMessage('{}')
      }
    },
    // 点击加入组织
    joinOrg() {
      this.$bus.$emit('join-org', { show: true, hidden: true })
    },
    // 点击联系方式
    onContact(type) {
      if (type === this.contact) {
        this.contact = type
        this.isShowContact = !this.isShowContact
      } else {
        this.contact = type
      }
    },
    // 关闭联系方式弹框
    showFalse() {
      this.isShowContact = false
      this.mechanismShow = false
    },
    // 常去机构
    getMechanismList() {
      var temp = []
      if (this.city) {
        temp = this.city.split('/')
      }
      let text = ''
      if (temp.length > 0) {
        text = temp[temp.length - 1]
      }
      const keyword = this.mechanismName ? this.mechanismName : text
      this.$axios.get(`${this.$base}/lts/org?type=${this.orgsType}&keyword=${keyword}`).then((res) => {
        if (res.data.code === '200') {
          this.mechanismList = res.data.data
        }
      })
    },
    mechanismFocus() {
      setTimeout(() => {
        this.mechanismShow = true
      }, 200)
      this.getMechanismList()
    },
    mechanismBlur() {
      this.mechanismShow = false
    },
    inputMechanismName() {
      this.getMechanismList()
    },
    mechanismClick(item) {
      const obj = {
        id: item.id,
        userId: this.userInfo.id,
        name: item.name,
        fullName: item.fullName,
        phone: item.phone,
        addr: item.address,
        type: this.orgsType,
      }
      this.orgs.push(obj)
      var result = []
      var org = {}
      for (var i = 0; i < this.orgs.length; i++) {
        if (!org[this.orgs[i].name]) {
          result.push(this.orgs[i])
          org[this.orgs[i].name] = true
        }
      }
      this.orgs = result
      this.mechanismShow = false
      this.mechanismName = ''
      this.save()
    },
    orgsTypeClick(item) {
      this.orgsType = item
      this.getMechanismList()
    },
    // 删除一个常去机构
    deleteOrgsName(item, i) {
      this.orgs.splice(i, 1)
      this.save()
    },
    // 获取用户信息
    getuserInfo() {
      this.$axios.get(`${this.$base}/management/user/${this.userInfo.id}/info`).then((res) => {
        const { code, data } = res.data
        if (code === 200 && data) {
          this.inputname = data.nickName
          this.inputidcard = data.idNumber
          this.email = data.email
          this.weixin = data.weixin
          this.qq = data.qq
          this.inputphone = data.phone
          this.city = data.county
          this.orgName = data.organizationName
          this.organizationId = data.organizationId
          this.orgs = data.orgs
          this.avatarUrl = data.avatarUrl
        }
      })
    },
    // 保存
    save() {
      if (this.inputidcard) {
        this.card()
        if (!this.isidnumber) {
          return this.$notify('身份证号码格式不正确！')
        }
      }
      if (!this.inputname) {
        return this.$notify('姓名不能为空！')
      }
      const params = {
        userId: this.userInfo.id,
        nickName: this.inputname,
        idNumber: this.inputidcard,
        phone: this.inputphone,
        avatarUrl: this.avatarUrl,
        weixin: this.weixin,
        orgs: this.orgs,
        qq: this.qq,
        email: this.email.replace('＠', '@'),
        county: this.city,
        organizationName: this.orgName,
        organizationId: this.organizationId,
      }
      this.$axios
        .post(`${this.$base}/management/user/info`, params)
        .then((res) => {
          if (res.data.code === 200) {
            this.$notify({
              type: 'success',
              message: '保存成功',
              duration: 800,
            })

            this.getuserInfo()
          } else {
            this.$notify('保存失败')
          }
        })
        .catch(() => {
          this.$notify('保存失败')
        })
    },
    card() {
      // if()
      // this.inputidcard = this.inputidcard.replace(/[\W]/g, '')
      const ph = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      if (this.inputidcard.length === 18) {
        if (ph.test(this.inputidcard) === false) {
          this.isidnumber = false
        } else {
          this.isidnumber = true
          // const sexnumber = this.inputidcard.charAt(16)
          // const agenumber = this.inputidcard.slice(6, 10)
          // if (sexnumber % 2 === 0) {
          //   this.sex = '女'
          // } else {
          //   this.sex = '男'
          // }
          // this.age = new Date().getFullYear() - agenumber
        }
      } else {
        this.isidnumber = false
        this.sex = ''
        this.age = null
        // this.$message.error('身份证号码格式不正确')
      }
    },
  },
  mounted() {
    this.getuserInfo()
  },
  created() {
    // 关联组件内方法ocrCaseResultCallback 至 JSBridge/uploadFiles
    this.$hook(this.$bridge.uploadFiles, this.uploadFilesResultCallBack)
    // 组件销毁的时候释放桥接方法
    this.$once('hook:destroyed', () => {
      this.$unhook(this.$bridge.uploadFiles)
    })
  },
}
</script>
<style lang="stylus">
.userInfo-page
  width 100%
  height 100%
  position relative
  & .head
    display flex
    justify-content space-between
    align-items center
    border-bottom-color green
    & .left
      font-size 0.8rem
      font-weight 900
      display flex
      align-items center
      &.arrow
        font-size 15px
        padding-left 0.2rem
  & .user-header
    padding 0 15px
    display flex
    align-items center
    box-sizing border-box
    & > img
      width 80px
      height 80px
      border-radius 40px
      border none
  & .numlist
    width 100%
    max-height 180px
    overflow auto
    background rgba(255, 255, 255, 1)
    box-shadow 0px 3px 8px 1px rgba(190, 190, 190, 0.64)
    border-radius 5px
    position fixed
    z-index 999
    li
      height 35px
      line-height 35px
      text-indent 10px
      white-space normal
      overflow hidden
      text-overflow ellipsis
      font-size 14px
      color #333
      & .org-title
        width 100%
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        padding 0 20px
        box-sizing border-box
  & .box
    padding 10px 15px
    display flex
    align-items center
    box-sizing border-box
    font-size 14px
    & .box-left
      width: 2.4rem
    & .box-right
      & > .iconfont
        margin-right 25px
  & .member-box
    display flex
    align-items center
    padding-right 10px
    box-sizing border-box
    & .icon-huiyuan1
      color #FF9900
      margin-right 5px
  & .contact
    width 80%
    background rgba(255, 255, 255, 1)
    box-shadow 0px 3px 8px 1px rgba(190, 190, 190, 0.64)
    border-radius 5px
    position fixed
    z-index 999
    left 10%
  & .line
    height 1px
    background rgba(0,0,0,0.1)
    width calc(100% - 15px)
    margin-left 15px
  & .orgs-list
    display flex
    align-items center
    padding 0 15px
    box-sizing border-box
    flex-wrap wrap
    & .orgs-name
      padding 5px 10px
      box-sizing border-box
      background #F2F2F2
      margin-right 5px
      border-radius 5px
      margin-bottom 5px
</style>
