var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "userInfo-page",
      style: `height:${_vm.clientHeight}px`,
      on: { click: _vm.showFalse },
    },
    [
      _c("join-organization"),
      _c("div", { staticClass: "head" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("van-icon", {
              staticClass: "arrow",
              attrs: { name: "arrow-left" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.back.apply(null, arguments)
                },
              },
            }),
            _c("van-cell", { attrs: { title: "个人中心" } }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "user-header" }, [
        _c("img", {
          attrs: { src: _vm.avatarUrl, alt: "" },
          on: { click: _vm.addImg },
        }),
        _c(
          "div",
          { staticStyle: { flex: "1" } },
          [
            _c("van-field", {
              attrs: { placeholder: "请输入用户名" },
              on: {
                blur: function ($event) {
                  return _vm.save()
                },
              },
              model: {
                value: _vm.inputname,
                callback: function ($$v) {
                  _vm.inputname = $$v
                },
                expression: "inputname",
              },
            }),
          ],
          1
        ),
      ]),
      _c("van-field", {
        staticClass: "vans",
        attrs: {
          clearable: "",
          label: "身份证号",
          placeholder: "请输入身份证号",
        },
        on: {
          blur: function ($event) {
            return _vm.save()
          },
        },
        model: {
          value: _vm.inputidcard,
          callback: function ($$v) {
            _vm.inputidcard = $$v
          },
          expression: "inputidcard",
        },
      }),
      _c("van-field", {
        staticClass: "vans",
        attrs: {
          clearable: "",
          label: "所在区县",
          placeholder: "请输入所在区县",
        },
        on: { input: _vm.inputCity, focus: _vm.cityFocus, blur: _vm.cityBlur },
        model: {
          value: _vm.city,
          callback: function ($$v) {
            _vm.city = $$v
          },
          expression: "city",
        },
      }),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.cityShow,
              expression: "cityShow",
            },
          ],
          staticClass: "numlist",
          on: {
            scroll: function ($event) {
              return _vm.cityOnLoad($event)
            },
          },
        },
        _vm._l(_vm.cityList, function (item, i) {
          return _c(
            "li",
            {
              key: i,
              on: {
                click: function ($event) {
                  return _vm.cityClick(item)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.provinceCityRegion) + " ")]
          )
        }),
        0
      ),
      _c("div", { staticClass: "member-box" }, [
        _c(
          "div",
          { staticStyle: { flex: "1" } },
          [
            _c("van-field", {
              staticClass: "vans",
              attrs: {
                value: _vm.phoneHide(_vm.inputphone),
                clearable: "",
                label: "手机号",
                disabled: true,
              },
            }),
          ],
          1
        ),
        _c("div", { on: { click: _vm.toMember } }, [
          _c("i", { staticClass: "iconfont icon-huiyuan1" }),
          _vm.userInfo.memberStatus === 1
            ? _c("span", { staticStyle: { color: "#ff9900" } }, [
                _vm._v(
                  "剩余" + _vm._s(_vm.userInfo.memberRemainingDays) + "天"
                ),
              ])
            : _c("span", [_vm._v("成为会员")]),
        ]),
      ]),
      _c("div", { staticClass: "line" }),
      _vm.orgName
        ? _c("van-field", {
            staticClass: "vans",
            attrs: { clearable: "", label: "所属组织", disabled: true },
            model: {
              value: _vm.orgName,
              callback: function ($$v) {
                _vm.orgName = $$v
              },
              expression: "orgName",
            },
          })
        : _c("div", { staticClass: "box", on: { click: _vm.joinOrg } }, [
            _c("div", { staticClass: "box-left" }, [_vm._v("所属组织")]),
            _c(
              "div",
              { staticClass: "box-right", staticStyle: { color: "#969799" } },
              [_vm._v("未加入组织，点击申请加入")]
            ),
          ]),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-left" }, [_vm._v("联系方式")]),
        _c("div", { staticClass: "box-right" }, [
          _c("i", {
            staticClass: "iconfont icon-wechat",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.onContact("wechat")
              },
            },
          }),
          _c("i", {
            staticClass: "iconfont icon-qq",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.onContact("qq")
              },
            },
          }),
          _c("i", {
            staticClass: "iconfont icon-mail",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.onContact("email")
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "line" }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowContact,
              expression: "isShowContact",
            },
          ],
          staticClass: "contact",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c("van-field", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.contact === "wechat",
                expression: "contact === 'wechat'",
              },
            ],
            staticClass: "vans",
            attrs: { placeholder: "请输入微信号" },
            on: {
              blur: function ($event) {
                return _vm.save()
              },
            },
            model: {
              value: _vm.weixin,
              callback: function ($$v) {
                _vm.weixin = $$v
              },
              expression: "weixin",
            },
          }),
          _c("van-field", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.contact === "qq",
                expression: "contact === 'qq'",
              },
            ],
            staticClass: "vans",
            attrs: { placeholder: "请输入QQ号" },
            on: {
              blur: function ($event) {
                return _vm.save()
              },
            },
            model: {
              value: _vm.qq,
              callback: function ($$v) {
                _vm.qq = $$v
              },
              expression: "qq",
            },
          }),
          _c("van-field", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.contact === "email",
                expression: "contact === 'email'",
              },
            ],
            staticClass: "vans",
            attrs: { placeholder: "请输入邮箱" },
            on: {
              blur: function ($event) {
                return _vm.save()
              },
            },
            model: {
              value: _vm.email,
              callback: function ($$v) {
                _vm.email = $$v
              },
              expression: "email",
            },
          }),
        ],
        1
      ),
      _c("van-field", {
        staticClass: "vans",
        attrs: {
          clearable: "",
          label: "常去机构",
          placeholder: "请输入机构名称",
        },
        on: { input: _vm.inputMechanismName, focus: _vm.mechanismFocus },
        model: {
          value: _vm.mechanismName,
          callback: function ($$v) {
            _vm.mechanismName = $$v
          },
          expression: "mechanismName",
        },
      }),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mechanismShow,
              expression: "mechanismShow",
            },
          ],
          staticClass: "numlist",
        },
        [
          _c("li", [
            _c(
              "div",
              { staticClass: "org-title" },
              _vm._l(_vm.orgsTypeList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    style: _vm.orgsType === item ? "color:#0066FF" : "",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.orgsTypeClick(item)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item) + " ")]
                )
              }),
              0
            ),
          ]),
          _vm._l(_vm.mechanismList, function (item, i) {
            return _c(
              "li",
              {
                key: i,
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.mechanismClick(item)
                  },
                },
              },
              [_vm._v(" " + _vm._s(item.name) + " ")]
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "orgs-list" },
        _vm._l(_vm.orgs, function (item, i) {
          return _c("div", { key: i, staticClass: "orgs-name" }, [
            _vm._v(" " + _vm._s(item.name) + " "),
            _c("i", {
              staticClass: "iconfont icon-clear",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.deleteOrgsName(item, i)
                },
              },
            }),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }